<template>
  <div>
       <!-- 创建 -->
        <div style="width:600px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.name" size="small" class="baseInput">
                    <template slot="prepend">名称</template>
                </el-input>

                <el-input placeholder="请输入内容" v-model="dynamic.saveData.number" size="small" class="baseInput">
                    <template slot="prepend">编号</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.address" size="small" class="baseInput" style="width:580px;">
                    <template slot="prepend">地址</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.contact_name" size="small" class="baseInput">
                    <template slot="prepend">联系人</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.phone" size="small" class="baseInput">
                    <template slot="prepend">手机号码</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.email" size="small" class="baseInput">
                    <template slot="prepend">E-mail</template>
                </el-input>
                <el-input placeholder="请输入内容" v-model="dynamic.saveData.qq" size="small" class="baseInput">
                    <template slot="prepend">qq</template>
                </el-input>
                <el-switch
                v-model="dynamic.saveData.enable_status"
                active-color="#13ce66"
                inactive-color="#ccc"
                inactive-text="状态">
                </el-switch>
                
                <el-row>
                    <div style="margin:10px 0;">备注：</div>
                    <textarea
                        style="height:100px;border:1px solid #C0C4CC;width:100%;"
                        type="textarea"
                        placeholder="请输入内容"
                        v-model="dynamic.saveData.remarks">
                        </textarea>
                </el-row>
                

                <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                    <el-button  @click="exit();fleshList();">关 闭</el-button>
                    <el-button type="primary" @click="save();">保 存</el-button>
                </div>
            </div> 
        </div>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            //用于初始化
            initData:null,
            dynamic:{
                saveData:{
                    name:'',
                    number:'',
                    address:'',
                    contact_name:'',
                    phone:'',
                    email:'',
                    qq:'',
                    enable_status:false,
                    remarks:''
                }
            }
        }
    },
    methods:{
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.dynamic.saveData.id = this.id;
                //保存  
                apis.shopSave(this.dynamic.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }
            //新增
            apis.shopAdd(this.dynamic.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.$message({
                    type:'success',
                    message:'添加店铺成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
    },
    destroyed(){
        this.dynamic = this.initData;
    },
    mounted(){
        if(this.dynamic === null ){
            this.initData = tools.copy(this.dynamic);
        }
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据,拉取等级列表
            apis.shopDetail({id:this.id}).then(res=>{
                if(tools.msg(res,this)){
                    this.dynamic.saveData = res.data;
                }
            }).catch(err=>{
                tools.err(err,this);
            });
        }
    }
}
</script>

<style>
  .baseInput{
      width:280px;margin:10px 5px;
  }
</style>